import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Inna Suslova" subtitle="HR Manager" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "679px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/feff1aa03857e1434e115119bf24959c/30e56/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAYDBAUB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwAB/9oADAMBAAIQAxAAAAHLv19Sl86OcjAtsgJgEJuf/8QAGhAAAgMBAQAAAAAAAAAAAAAAAgMAARIEI//aAAgBAQABBQJYbJ3NQriId1mCWbNY0LM2UR6ck//EABcRAAMBAAAAAAAAAAAAAAAAAAECICH/2gAIAQMBAT8BUZH/xAAZEQACAwEAAAAAAAAAAAAAAAAAAQIQESH/2gAIAQIBAT8BlLtNaaf/xAAeEAABBAEFAAAAAAAAAAAAAAABABARMVESEyJBYf/aAAgBAQAGPwKFqBYnCoePudWuFNBxDf/EABoQAAMBAQEBAAAAAAAAAAAAAAABESFBEFH/2gAIAQEAAT8hglqQzhCU3ir0zw63OolTwKLwwh4/QbD/2gAMAwEAAgADAAAAED/LT//EABgRAQADAQAAAAAAAAAAAAAAAAEAEBEh/9oACAEDAQE/EBeqHJhP/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARIRD/2gAIAQIBAT8QYqcDTBGXP//EAB4QAQACAgIDAQAAAAAAAAAAAAEAESExQVEQYXHB/9oACAEBAAE/EMjULl9RUZNoOPvhRoQDXZtlfKebe+peYQGduw6hGwRrwm4ypyqrnmLLPYBPwa/JbCf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/feff1aa03857e1434e115119bf24959c/30e56/01.jpg",
              "srcSet": ["/static/feff1aa03857e1434e115119bf24959c/f93b5/01.jpg 300w", "/static/feff1aa03857e1434e115119bf24959c/b4294/01.jpg 600w", "/static/feff1aa03857e1434e115119bf24959c/30e56/01.jpg 679w"],
              "sizes": "(max-width: 679px) 100vw, 679px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <p>{`The Human Resources Department is committed to partnering with the school community in attracting and retaining a first-rate, talented and diverse staff. Our team is passionate about promoting and maintaining a culture of employee engagement and inclusiveness where work is meaningful, employees are valued, and collaboration is celebrated. To achieve these objectives, the department provides a full-range of support services, programmes and resources in the areas of employee benefits, compensation, compliance, employee relations, recognition, talent management and training.`}</p>
    <h4 {...{
      "id": "recruitment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#recruitment",
        "aria-label": "recruitment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recruitment`}</h4>
    <p>{`This year we successfully filled twenty teaching and learning support positions. This includes sixteen replacements, a Primary Principal, and one Home Learning Coordinator who joined us from the British Council.`}</p>
    <h4 {...{
      "id": "wellbeing-program-for-national-employees",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#wellbeing-program-for-national-employees",
        "aria-label": "wellbeing program for national employees permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wellbeing Program for national employees`}</h4>
    <p>{`This year we have partnered with “Wellbeing Company” to provide psychological support to our employees. It is aimed at improving employees’ workplace experience, providing  access to the best psychologists and coaches. Outcomes expected are: improvement of engagement, performance and motivation, increase in trust and loyalty, positive effect on employer’s brand.`}</p>
    <h4 {...{
      "id": "professional-development",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#professional-development",
        "aria-label": "professional development permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Professional Development`}</h4>
    <p>{`This year we have provided a number of opportunities for our colleagues to develop their skills. The courses offered were: How to Manage Difficult Conversations, Work-Life Balance: a New Perspective and GDPR: Personal data processing principles and the legal basis.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      